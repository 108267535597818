import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "shop",
  components: {},
  data() {
    return {
      collectionList: []
    }
  },
  created() {

  },
  computed: {
    ...mapGetters({
      list: 'category/categoriesList'
    }),
    sortedList(){
      return this.list.filter((item)=>item.typeCategorySlug === 'evening')
    }
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods: {}
}